import React from "react";
import './About.css';
import Member from '../member/Member.js';

function About() {

    const user1Data = {
        name: 'Kuba'
    };
      
    const user2Data = {
        name: 'Krzysiek'
    };

    return (
        <div className="about-us container" id='about'>
            <div className="about-us title">O nas</div>
            <div className="about-us description">
                <p>
                    To nasza pasja do filmu i fotografii samochodowej wyznacza 
                    nasz kierunek. Łączymy siły, by przekazać wizualne historie 
                    związane z motoryzacją. Nasze kamery i aparaty są naszym 
                    narzędziem do uwieczniania każdego detalu, mocy i piękna, 
                    które kryje się w świecie samochodów. Z dumą oddajemy naszą 
                    wiedzę i umiejętności w tworzeniu dynamicznych filmów oraz 
                    fotografii, które oddają esencję każdego pojazdu. Razem podróżujemy przez świat motoryzacji, 
                    by podzielić się z widzami pasją, której nieustannie się oddajemy.
                </p>
            </div>
            <div className="about-us photos">
                <div className="about-us photos member">
                    <Member user1={user1Data} user2={user2Data}/>
                </div>
            </div>
        </div>
    )
}

export default About;