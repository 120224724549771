import './Videos.css';
import React, { useState } from 'react';
import video1 from '../../assets/videos/video1.mp4';
import video2 from '../../assets/videos/video2.mp4';
import video3 from '../../assets/videos/video3.mp4';
import video4 from '../../assets/videos/video4.mp4';

const Videos = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const videos = [video1, video2, video3, video4];

  const handleThumbnailClick = (viedoSrc) => {
    setSelectedVideo(viedoSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedVideo(null);
    setIsModalOpen(false);
  };

  return (

    <div className="video-container">
        <div className="video-row container-fluid">
            {videos.map((video, index) => (
                <div className="video">
                    <video controls width="100%">
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            ))}
        </div>
    </div>
  );
}


export default Videos;