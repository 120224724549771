import React from "react";
import "./FullResImageModal.css";

function FullResImageModal({ isOpen, onClose, imageUrl }) {
    return (
      <div className={`modal ${isOpen ? 'open' : ''}`} onClick={onClose}>
        <div className="modal-content">
          <span className="close-button" onClick={onClose}>&times;</span>
          <img src={imageUrl} alt="Full Resolution Image" className="full-res-image" />
        </div>
      </div>
    );
  }
  
  export default FullResImageModal;