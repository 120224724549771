// import logo from './logo.svg';
import './App.css';
import React from 'react';
import Navbar from './components/navbar/Navbar.js';
import Slider from './components/slider/Slider.js';
import About from './components/about/About.js';
import Offer from './components/offer/Offer';
import Gallery from './components/gallery/Gallery.js';
import Contact from './components/contact/Contact.js';
import Footer from './components/footer/Footer';

//photos
import image1 from './assets/images/slider/image1.png';
import image2 from './assets/images/slider/image2.png';
import image3 from './assets/images/slider/image3.png';
import image4 from './assets/images/slider/image4.png';

const images = [image1, image2, image3, image4];

function App() {
  return (
    <div className="App">
        <Navbar />
        <Slider images={images} />
        <About />
        <Offer />
        <Gallery />
        <Contact />
        <Footer />
    </div>
  );
}

export default App;
