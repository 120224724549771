import './Memeber.css';
import image_kuba from '../../assets/images/members/image_kuba.jpg';
import image_krzysiek from '../../assets/images/members/image_krzysiek.jpg';

const Member = (props) => {

    const { user1, user2 } = props; 

    return (

        <div className="member">
            <div className="user">
                <img src={image_kuba}></img>
                <figcaption>{user1.name}</figcaption>
            </div>
            <div className="user">
                <img src={image_krzysiek}></img>
                <figcaption>{user2.name}</figcaption>
            </div>
        </div>
    )
}

export default Member;

