import React, { useState, useEffect } from 'react';
import photomotoLogo from '../../assets/logo/photomoto.png';
import photomotoSignet from '../../assets/logo/photomoto_turbo.png';

function Logo() {
    const [ isMobile, setIsMobile ] = useState(window.innerWidth <= 991);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 991);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    return (
        <img
            className={`logo ${isMobile ? 'mobile' : 'desktop'}`}
            src={isMobile ? photomotoSignet : photomotoLogo}
            height="32"
            alt="Logo"
        />
    );
}

export default Logo;