import React from "react";
import logo from "../../assets/logo/photomoto.png"
import { ReactComponent as FacebookIcon } from "../../assets/icons/square-facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/square-instagram.svg";
import { Link as LinkScroll } from 'react-scroll';
import "./Footer.css";

const Footer = () => {

    const date = new Date();
    const year = date.getFullYear();

    const options = {
        activeClass: "start-button",
        spy: true,
        smooth: true,
        offset: 50,
        duration: 500
    }

    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="footer-container">
                    <div className="footer-section">
                        <ul class="nav-links"><h4>Links</h4>
                            <li class="nav-item">
                                <LinkScroll to="about" className="nav-link" {...options}>O nas</LinkScroll>
                            </li>
                            <li class="nav-item">
                                <LinkScroll to="offer" className="nav-link" {...options}>Oferta</LinkScroll>
                            </li>
                            <li class="nav-item">
                                <LinkScroll to="gallery" className="nav-link" {...options}>Galeria</LinkScroll>
                            </li>
                            <li class="nav-item">
                                <LinkScroll to="contact" className="nav-link" {...options}>Kontakt</LinkScroll>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-section">
                        <div className="logo">
                            <a href="#">
                                <img src={logo} width="150" alt="photomoto_logo" />
                            </a>
                        </div>
                        <div className="social-media">
                            <FacebookIcon className="fb" width="32" height="32" />
                            <a href="https://www.instagram.com/photomotopl/" target="blank">
                                <InstagramIcon className="ig" width="32" height="32" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="copyright">Copyright &copy; {year} Photomoto</div>
            </div>
        </div>
    )
}

export default Footer;