import './Offer.css';

const Offer = () => {
    return (
        <div className="offer container" id="offer">
            <div className="offer title">Oferta</div>

            <div className="description">

                <p>Szukasz profesjonalnych i innowacyjnych sposobów, aby uwiecznić piękno swojego samochodu lub motocykla?<br />
                    Jesteś we właściwym miejscu! Nasza firma specjalizuje się w fotografii samochodów i motocykli, 
                    korzystając z najnowszych technologii, w tym dronów i kamery 360 stopni.<br /><br /><strong>Oto, co możemy dla Ciebie zrobić:</strong>
                </p>

                <p>
                    <strong>Fotografia powietrzna dronem:</strong><br />
                    Dzięki naszym zaawansowanym dronom tworzymy widowiskowe ujęcia z powietrza, 
                    ukazujące Twój pojazd z niecodziennej perspektywy. Doskonałe dla tych, którzy chcą uchwycić swoje auto lub motocykl w pełnej okazałości.
                </p>

                <p>
                    <strong>Fotografia 360 stopni:</strong><br />
                    Nasza kamera 360 stopni pozwala stworzyć interaktywne i immersywne wirtualne wycieczki po wnętrzu i zewnętrzu pojazdu. 
                    Potencjalni klientowie mogą zobaczyć każdy szczegół z każdego kąta, jakby byli na miejscu.
                </p>

                <p>
                    <strong>Sesje plenerowe i studyjne:</strong><br />
                    Niezależnie od tego, czy chcesz malowniczych zdjęć na tle przyrody czy eleganckich ujęć w studio, nasz zespół zapewni Ci sesję dopasowaną do Twoich wymagań.
                </p>

                <p>
                    <strong>Profesjonalny retusz i edycja:</strong><br />
                    Nasz doświadczony zespół grafików zadba o perfekcyjny wygląd każdego zdjęcia, nadając mu niepowtarzalny charakter i klasę.
                </p>

                <p>
                    <strong>Szybka dostawa i konkurencyjne ceny:</strong><br />
                    Oferujemy szybki czas realizacji projektów oraz konkurencyjne ceny, abyś mógł cieszyć się doskonałymi zdjęciami bez nadmiernego obciążenia budżetu.
                </p>

                <p>
                    Zaufaj ekipie <strong>PhotoMoto</strong>, a przekonasz się, że Twoje pojazdy zasługują na najlepsze ujęcia! <br />Skontaktuj się z nami już dziś, 
                    aby omówić swoje potrzeby fotograficzne i stworzyć niezapomniane obrazy swoich samochodów i motocykli.
                </p>

            </div>

        </div>

    )
}


export default Offer;