
import React, { useState, Component } from 'react';
import './Gallery.css';
import Photos from '../photos/Photos.js';
import Videos from '../videos/Videos.js';

function Gallery() {
    const  [currentContent, setCurrentContent] = useState('photos');

    const handleContentChange = (content) => {
        setCurrentContent(content);
    };

    return (
        <div className="gallery container">
            <div className="gallery title">Galeria</div>
            <div className="gallery container-fluid">
                <button
                    onClick={() => handleContentChange('photos')}
                    className={`gallery-tab photos effect01 ${currentContent === 'photos' ? 'active' : ''}`}
                >
                    <span>Photos</span>
                </button>
                <button
                    onClick={() => handleContentChange('videos')}
                    className={`gallery-tab photos effect01 ${currentContent === 'videos' ? 'active' : ''}`}
                >
                    <span>Videos</span>
                </button>

                <div>
                    {currentContent === 'photos' && <Photos />}
                    {currentContent === 'videos' && <Videos />}
                </div>
                
            </div>


        </div>
    )

} 

export default Gallery;