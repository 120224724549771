
import './Contact.css';
import { Component, useState } from 'react';
import Loader from '../loader/Loader.js';


class Contact extends Component {

    UseEmail = (endpointUrl) => {
        const [ submitted, setSubmitted ] = useState(false);
        const [ loading, setLoading ] = useState(false);
        const [ error, setError ] = useState('');

        const sendEmail = (data) => {
            setLoading(true);
            setSubmitted(false);
            setError('');

            fetch(endpointUrl, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            .then((response) => {

                if (response.status === 422) {
                    throw new Error('Are you robot?');
                }

                if (response.status !== 200) {
                    throw new Error(`${response.statusText} (${response.status})`);
                }

                return response.json();
            })
            .then(() => {
                setSubmitted(true);
                setLoading(false);
            })
            .catch((err) => {
                setError(err.toString());
                setLoading(false);
            });
        };

        return {
            submitted,
            loading,
            error,
            sendEmail,
        };
    };

    render() {
        return (
            <div className="contact container" id="contact">
                <div className="contact title">Kontakt</div>
                <div className="contact-section">

                    <p>Dziękujemy za zainteresowanie Naszymi usługami fotograficznymi dla samochodów i motocykli.</p>

                    <p>Prosimy o wypełnienie poniższego formularza, aby się z Nami skontaktować.</p>

                    <p>Nasz zespół odpowie na Twoje pytania w możliwie najkrótszym czasie i pomoże zorganizować sesję zdjęciową dla Twojego pojazdu.</p>
                </div>

                <div className="contact-section form"> 

                    <form 
                        action="https://public.herotofu.com/v1/b8433b10-5163-11ee-9a5e-cf86fe56739d" 
                        method="post" 
                        acceptCharset="UTF-8"
                        >
                        <div class="mb-3">
                            <input type="text" name="Name" class="form-control" id="nameInput" placeholder="Your name" required />
                        </div>

                        <div class="mb-3">
                            <input type="email" name="Email" class="form-control" id="emailInput" placeholder="name@example.com" required />
                        </div>

                        <div class="mb-3">
                            <textarea name="Message" class="form-control" id="textInput" rows="3" placeholder="Your question" required></textarea>
                        </div>
                        
                        <button className="gallery-tab effect01 clip"
                            type="submit" value="Submit"><span>Wyślij</span>
                        </button>
                    </form>

                </div>

                </div>
        )
    }
}

export default Contact;