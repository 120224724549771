import React, { useState } from 'react';
import FullResImageModal from '../fullResImageModal/FullResImageModal.js';
import image1 from "../../assets/images/autos/image1.jpeg";
import image2 from "../../assets/images/autos/image2.jpeg";
import image3 from "../../assets/images/autos/image3.jpeg";
import image4 from "../../assets/images/autos/image4.jpeg";
import image5 from "../../assets/images/autos/image5.jpeg";
import image6 from "../../assets/images/autos/image6.jpeg";
import image7 from "../../assets/images/autos/image7.jpeg";
import image8 from "../../assets/images/autos/image8.jpeg";
import './Photos.css';

function Photos() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [image1, image2, image3, image4, image5, image6, image7, image8];

  const handleThumbnailClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  return (
    <div className="photos container" id="photos">
      <div className="thumbnail-row container-fluid">
        {images.map((image, index) => (
          <div
            className="thumbnail"
            key={index}
            onClick={ () => handleThumbnailClick(image) }  
          >
            <img src={image} alt="Thumbnail" />

          </div>
        ))}
      </div>

      <FullResImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageUrl={selectedImage}
      />

    </div>
  );
  
};

export default Photos;
