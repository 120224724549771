import './Navbar.css';
import React, { Component } from 'react';

import { Link as LinkScroll } from 'react-scroll';
import Logo from '../logo/Logo.js';

const options = {

    activeClass: "start-button",
    spy: true,
    smooth: true,
    offset: 50,
    duration: 500
}

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <Logo />
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <LinkScroll to="about" className="nav-link" {...options}>O nas</LinkScroll>
                        </li>
                        <li class="nav-item">
                            <LinkScroll to="offer" className="nav-link" {...options}>Oferta</LinkScroll>
                        </li>
                        <li class="nav-item">
                            <LinkScroll to="gallery" className="nav-link" {...options}>Galeria</LinkScroll>
                        </li>
                        <li class="nav-item">
                            <LinkScroll to="contact" className="nav-link" {...options}>Kontakt</LinkScroll>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;